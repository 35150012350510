@import "../../../theme.scss";

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    h3 {
        font-weight: 600;
    }
    svg {
        font-size: 24px;
        margin-right: 10px;
        font-weight: 700;
        margin-bottom: 8px;
    }
}

// #learn-header{
//     background-image: url("../../../assets/images/vector.png"), url("../../../assets/images/vector2.png")!important;
//     background-position: -10% 90%, 130% 10%!important;
//     background-repeat: no-repeat!important;
//     background-size: 50%!important;

// }
.learn-header {
    position: relative;
    height: auto;
    width: 100%;
    min-height: 300px;
    border-radius: 20px;
    padding: 30px;
    border: 1px solid;
    box-shadow: 4px 8px 24px rgba(255, 255, 255, 0.15);
    .top-detail {
        span {
            font-weight: 700;
        }
        h2 {
            margin-top: 10px;
            color: $font-black-color;
            font-weight: 700;
        }
        p {
            opacity: 0.6;
        }
    }
    .bot-detail {
        display: flex;
        align-items: center;
        gap: 0 5px;
        span {
            display: flex;
            align-items: center;
            font-size: 0.8em;
            gap: 0 5px;
            opacity: 0.6;
            font-weight: 600;
        }
    }
    .card {
        width: 300px;
        border-radius: 10px !important;
        padding: 20px;
        min-height: 300px;
        img {
            margin-bottom: 10px;
        }
        .detail {
            margin-bottom: 10px;
            span {
                font-weight: 600;
            }
        }
    }
    @media only screen and (max-width: 920px) {
        height: auto;
    }
    .button-block {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        gap: 0 5px;
        .join {
            padding: 5px;
            flex-basis: 80%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            font-weight: 600;
            &:hover {
                background: $secondary-color !important;
                color: $white-color !important;
            }
        }
        .like {
            padding: 5px;
            flex: 1;
            background: $white-color;
            border: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}
.learn-body {
    .learn-course {
        margin: 10px 0;
        .accordion-button:not(.collapsed) {
            background: transparent;
            color: inherit;
        }
    }
    .learn-content {
        padding: 20px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        min-height: 300px;
        p {
            opacity: 0.6;
        }
    }
}

.learn-form {
    background-color: $white-color;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 4px 8px 24px rgba(0, 0, 0, 0.05);
    .date {
        margin: 0 0 30px 0;
    }
    .learn-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 50%;
        }
        .title {
            font-size: 1.75rem;
            font-weight: 600;
        }
        .bookmark {
            font-size: 1.25rem;
            background: none !important;
            border: none !important;
        }
    }
    .avainfo {
        display: flex;
        flex: 0 1;
        justify-content: space-between;
        align-items: center;
        .ava {
            display: flex;
            align-items: center;
            img {
                width: 50px;
                height: 50px;
                border-radius: 10px;
                object-fit: cover;
                margin: 0 5px;
            }
            .avaid {
                h5 {
                    font-weight: 600;
                    font-size: 1.15rem;
                    line-height: 0.75rem;
                }
                span {
                    font-size: 0.75rem;
                    opacity: 0.6;
                }
            }
        }
        .category-col {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-end;
            align-self: flex-end;
            width: 70%;
            .category {
                font-size: 0.75rem;
                padding: 5px 10px;
                margin: 4px 2px;
                cursor: pointer;
                border-radius: 16px;
                span {
                    text-align: center;
                }
            }
        }
    }
    .learn-body {
        margin: 20px 0;
    }
    .learn-bottom {
        display: flex;
        justify-content: space-between;
        .response {
            display: flex;
            align-items: center;
            cursor: pointer;
            gap: 0 5px;
            font-size: 0.75rem;
            font-weight: 600;
            padding: 5px 10px;
            color: #999;
            background: $light-grey-color;
            border-radius: 3px;
            &:hover {
                background: $secondary-color;
                color: #fff;
            }
        }
    }
}
.learn-create {
    label {
        font-weight: 700;
    }
    input {
        background-color: #ececec;
    }
    textarea {
        background-color: #ececec;
        min-height: calc(10.5em + 0.175rem + 5px) !important;
    }
    .form-control {
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
    .form-control:focus {
        border: 3px solid $secondary-color;
        box-shadow: none;
    }
    .tag-wrapper {
        background-color: $secondary-color;
        padding: 5px;
        color: #fff;
        margin-right: 10px;
        button {
            background-color: $secondary-color;
            border: none;
            color: #fff;
        }
    }
    .ReactTags__tagInput input {
        border: 1px solid #ececec;
        padding: 10px;
        width: 100%;
        outline: none;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        margin: 10px 0;
        &:focus {
            border: 3px solid $secondary-color !important;
        }
    }
    .accordion-body {
        .date {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 5px 0;
            gap: 0 5px;
        }
        .item-element {
            margin: 15px 0;
        }
    }
    .accordion-button:not(.collapsed) {
        box-shadow: none;
    }
    .accordion-button:focus {
        box-shadow: none;
    }
    .section-btn {
        display: flex;
        justify-content: center;
        border: 1px solid rgba($primary-color, 0.5);
        cursor: pointer;
        margin: 10px 0;
        padding: 16px;
        border-radius: 5px;
        color: $primary-color !important;
    }
}
.side-block {
    .learn-bottom {
        margin: 30px 0;
    }
    .learn-card-side {
        display: flex;
        position: relative;
        height: 80px;
        * {
            margin-right: 20px;
        }
        img {
            background-color: #afc2d5;
            width: 30%;
            object-fit: contain;
            border-radius: 10px;
            padding: 20px;
            &.no-padding {
                padding: 2px !important;
            }
        }
        .card-body-top {
            font-size: 0.75rem;
            opacity: 0.7;
        }
        .card-body-title {
            font-size: 0.85rem;
            font-weight: 600;
        }
        .card-body-bottom {
            position: absolute;
            bottom: 0;
            font-size: 0.75rem;
            opacity: 0.6;
        }
    }

    .learn-col:nth-of-type(3n + 1) .learn-card-side img {
        background-color: #f9dbbd;
    }
    .learn-col:nth-of-type(3n + 2) .learn-card-side img {
        background-color: #e8fccf;
    }
    .learn-col:nth-of-type(3n + 3) .learn-card-side img {
        background-color: #afc2d5;
    }
}
.comment-section {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    a {
        font-weight: 600;
        cursor: pointer;
    }

    p {
        margin-bottom: 0 !important;
    }

    .comments-all {
        margin: 15px 0;
        padding: 20px 0 0 0;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        display: flex !important;
        justify-content: space-between !important;
        span {
            font-weight: 500;
        }
    }
    .comment-form {
        margin: 10px 0;
        width: 100%;
        * {
            margin: 5px 0;
        }
        textarea {
            background: #ececec;
            min-height: 100px;
        }
        .answer {
            padding: 0.65rem 1.15rem;
            border-radius: 3px;
            font-size: 0.75rem;
            font-weight: 600;
            cursor: pointer;
            border: none;
            background: $primary-color;
            color: #fff;
            &:hover {
                border: 1px solid $primary-color;
                background: #fff;
                color: $primary-color;
            }
        }
    }

    .comment-list {
        margin-bottom: 50px;
        .comment-card {
            display: flex;
            gap: 0 20px;
            background: $white-color;
            border: 1px solid rgba($grey-color, 0.4);
            padding: 10px 15px;
            border-radius: 10px;
            .comment-top {
                display: flex;
                justify-content: flex-start;
                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
            .comment-detail {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 5px 0;
                flex: 1;
                .title {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    width: 100%;
                    span {
                        font-size: 14px;
                        font-weight: 600;
                        padding: 0 !important;
                    }
                    .date {
                        font-size: 12px;
                        opacity: 0.6;
                    }
                }
                p {
                    opacity: 0.6;
                }
                .comment-bottom {
                    width: 100%;
                    a {
                        font-size: 12px;
                        padding-bottom: 10px;
                        cursor: pointer;
                        margin: 5px 0;
                        &:hover {
                        }
                    }
                }
            }
        }
        .comment-child {
            min-height: 50px;
            padding: 5px 3px 0 10px;
            border-radius: 3px;
            display: flex;
            gap: 0 4px;
            background: rgba($light-grey-color, 0.5);
            border-bottom: 1px solid rgba($grey-color, 0.5);
            .title {
                display: flex;
                flex-direction: row !important;
                gap: 0 !important;
            }
            img {
                width: 25px !important;
                height: 25px !important;
            }
            p {
                font-size: 12px;
            }
            &:last-child {
                border-bottom: none;
            }
        }
        .more-btn {
            margin-top: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
            &:hover {
                color: rgba($primary-color, 1);
                &:before,
                &:after {
                    content: "";
                    flex: 1;
                    border-bottom: 1px solid rgba($primary-color, 0.5);
                }
            }
            &:before,
            &:after {
                content: "";
                flex: 1;
                border-bottom: 1px solid rgba($grey-color, 0.8);
            }
        }
        .more-btn:not(:empty)::before {
            margin-right: 0.5em;
        }

        .more-btn:not(:empty)::after {
            margin-left: 0.5em;
        }
    }
}

.youtube-player {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
    margin: 5px;
}

.youtube-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: transparent;
}

.youtube-player img {
    object-fit: cover;
    display: block;
    left: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    height: auto;
    cursor: pointer;
    -webkit-transition: 0.4s all;
    -moz-transition: 0.4s all;
    transition: 0.4s all;
}

.youtube-player img:hover {
    -webkit-filter: brightness(75%);
}

.youtube-player .play {
    height: 72px;
    width: 72px;
    left: 50%;
    top: 50%;
    margin-left: -36px;
    margin-top: -36px;
    position: absolute;
    background: url("//i.imgur.com/yq7peaM.png") no-repeat;
    cursor: pointer;
}

.delete-container {
    width: 80px;
    height: 50px;
    background: $primary-color;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 40px 0 0;
}

.btn-delete-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: transparent !important;
    &:hover {
        background-color: transparent !important;
    }
}

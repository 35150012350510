@import "./theme.scss";
@import "./assets/css/background.scss";
@import "./assets/css/button.scss";
@import "./assets/css/display.scss";
@import "./assets/css/font.scss";
@import "./assets/css/image.scss";
@import "./assets/css/input.scss";
@import "./assets/css/margin.scss";
@import "./assets/css/padding.scss";
@import "./assets/css/position.scss";
@import "./assets/css/text.scss";
@import "./assets/css/width.scss";
@import "./assets/css/others.scss";
@import "./assets/css/loading.scss";
@import "~react-datepicker/dist/react-datepicker.css";

// adjustment css
body {
  font-family: $font-stack;
  background-color: $bg-color !important;
  overflow-x: hidden;
  color: $font-black-color !important;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none !important;
  color: $font-black-color !important;
  transition: 0.3s;
  &:hover {
    color: $primary-color !important;
  }
}

.se-image-container {
  margin: 15px 0;
}

#navbar {
  display: flex;
  justify-content: space-between !important;
  z-index: 9999 !important;
  transition: 0.5s ease-in-out;
}

.sticky {
  position: sticky !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background: $bg-color;
  width: 100%;
  -webkit-box-shadow: 0 2px 2px 0 rgb(51 51 51 / 1%),
    0 3px 1px -2px rgb(51 51 51 / 2%), 0 1px 20px 0 rgb(51 51 51 / 10%);
  box-shadow: 0 2px 2px 0 rgb(51 51 51 / 1%), 0 3px 1px -2px rgb(51 51 51 / 2%),
    0 1px 20px 0 rgb(51 51 51 / 10%);
}

.brand {
  img {
    float: left;
    height: 64px;
    width: auto;
    @media only screen and (max-width: 750px) {
      margin: auto;
      width: auto;
    }
  }
}
#dropdown {
  margin: 0 !important;
  font-weight: 500 !important;
  .accordion-item {
    padding: 0 !important;
    margin: 0 !important;
    background: transparent !important;
    .accordion-header {
      padding: 0 !important;
      margin: 0 !important;
      a{
          margin: 0;
      }
    }
    .accordion-body {
      padding: 0 !important;
      margin: 0 !important;
      .accordion {
        margin: 0 !important;
        .accordion-item {
          padding: 0 !important;
          margin: 0 !important;
        }
        .accordion-button{
            font-size: 14px!important;
        }
        
        ul{
            list-style: none;
            margin: 0;
            padding: 0 0 10px 0;
            li{
            margin: 0;
            padding: 0;
            a{
                font-size: 14px;
                font-weight: 400;
            margin: 0;
            padding: 0;
            }
            }
        }
      }
    }
    .accordion-button {
      margin: 0 !important;
      border: none !important;
      color: $font-black-color !important;
      background: transparent !important;
      box-shadow: none !important;
      padding: 10px 10px !important;
      font-weight: 500 !important;
      &::after{
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000000'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
      }
    }
  }
}
.sidenav {
  @media only screen and (max-width: 992px) {
    border-radius: 0;
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    margin: 0;
    background-color: $light-grey-color; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s;
  }
}
.navbar-collapse {
  flex-grow: 0 !important;
}
.navbar-light .navbar-toggler {
  border: transparent !important;
}
.dropdown {
  position: relative;
  display: inline-block;
  margin-left: 0 !important;
  &:hover .dot-bottom {
    background-color: $primary-color !important;
  }
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 250px;
  margin-left: 0 !important;
  z-index: 1;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin: 0;
      padding: 0;
      a {
        font-size: 14px;
        padding: 0 1rem;
        display: block;
      }
      &:hover {
        background: rgba($color: $primary-color, $alpha: 0.1);
        .submenu-link {
          color: $primary-color !important;
        }
        @media only screen and (max-width: 920px) {
          background-color: transparent;
        }
      }
      @media only screen and (max-width: 920px) {
        padding: 0;
      }
    }
    @media only screen and (max-width: 920px) {
      background-color: #ffffff;
    }
  }
}
.has-submenu {
  position: relative;
}
.submenu {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  background: #f1f1f1;
  color: #000;
  min-width: 250px;
  li {
    padding: 0 2rem;
    &:hover .sub-submenu-link {
      color: $primary-color !important;
    }
  }
  @media only screen and (max-width: 920px) {
    background: #e7e7e7 !important;
  }
}
.has-submenu:hover .submenu {
  display: block;
  @media only screen and (max-width: 920px) {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    left: 0;
  }
}
.dropdown:hover .dropdown-content {
  display: block;
  @media only screen and (max-width: 920px) {
    position: relative;
    display: block;
    width: 100%;
    text-align: left;
    background-color: transparent;
  }
}
.navbar-light .navbar-toggler-icon {
  background-image: url("./assets/images/user.svg") !important;
  width: 24px;
  height: 24px;
}
.navbar-fixed-top {
  top: -70px; /* you'll have to figure out the exact number here */
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  position: relative; /* this can also be static */
}
.navbar-nav .nav_link {
  position: relative;
  margin: 0 10px;
  line-height: 3;
  font-weight: 500;
  color: $font-black-color !important;
  transition: 0.3s;
  &:hover {
    color: $primary-color !important;
  }
  &:hover .dot-bottom {
    background-color: $primary-color;
  }
  &:active {
    color: $primary-color !important;
  }
}

.navbar-nav .active {
  color: $primary-color !important;
}

.navbar-nav .active.btn-primary.register-btn {
  color: #fff !important;
}

.navbar-nav .active .dot-bottom {
  background-color: $primary-color !important;
  margin-left: 0 !important;
  margin-bottom: 0 !important;
}

.nav-link.active {
  background-color: transparent !important;
}

.contact-maps {
  &.lg {
    & iframe {
      position: relative;
      border-radius: 25px;
      width: 100%;
      height: 563px;
      display: block;
    }
  }
  &.sm {
    & iframe {
      position: relative;
      border-radius: 25px;
      width: 100%;
      height: 563px;
      display: none;
    }
  }
}

.pagination li {
  margin-right: 10px;
  & a {
    background: #ffffff;
    border: 1px solid #f9d6c0;
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    position: static;
    width: 50px;
    height: 50px;
    left: 60px;
    top: 0px;
  }
  &:last-child {
    margin-right: 0px !important;
  }
  &.active {
    & a {
      background: #e85d04;
      border: 1px solid #f9d6c0;
      color: #ffffff !important;
    }
  }
}

.footer {
  min-height: 300px;
  background: $grey-color;
  padding: 50px 0 30px 0;
  .brand {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    * {
      padding: 5px 0;
    }
    .logo {
      width: 100%;
      padding: 0 !important;
      display: inline-block;
      img {
        width: 50%;
        padding: 0 !important;
        height: auto;
      }
    }
  }
  .item {
    color: $font-black-color;
    ul {
      list-style: none;
    }
  }
  p {
    display: block;
    width: 100%;
    color: $font-black-color !important;
  }
  .copy {
    padding: 10px 0;
    color: $font-black-color !important;
    text-align: center;
  }
}

.loader-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  & img {
    width: 10rem;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}

.list-group-item {
  border: none !important;
  border-bottom: 1px solid #ededed !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media only screen and (max-width: 920px) {
  .contact-maps {
    &.lg {
      & iframe {
        position: relative;
        border-radius: 0;
        width: 100%;
        height: 250px;
        display: none;
      }
    }
    &.sm {
      & iframe {
        position: relative;
        border-radius: 0;
        width: 100%;
        height: 250px;
        display: block;
      }
    }
  }

  .activity h4 {
    font-size: 1rem !important;
  }

  .hide-mobile {
    display: none !important;
    visibility: hidden !important;
  }
}

@media only screen and (min-width: 920px) {
  .hide-desktop {
    display: none;
    visibility: hidden;
  }
}

// end adjustment css
